<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">
  
    <POrderGrid
      ref="ordergrid"      
      transactionType="TO"
      :locationIdTo="location.id"
      :cols="cols"
      :filters="filters"
      :orderBy="orderBy"
      :expandAll="expandAll"      
      @click="onClick"
    ></POrderGrid>

    <portal to="filters">
        <POrderFilters v-model="filters" />
    </portal>

    <portal to="actions">
      <div class="ml-4 space-x-4 flex items-center">
        
        <PToggle 
          v-model="expandAll" 
          :label="$tk('Common.General.Detailed')" 
          :stacked="true" 
        />

        <PButton 
          color="primary"
          v-hint="{ show: hintsVisible, text: 'N' }"          
          v-shortkey.push="['alt', 'n']"          
          @shortkey.native="onCreateShow"
          @click="onCreateShow"
          :disabled="customer.statusId === 2 || location.statusId === 2"
        >
          <span v-html="$tk('Orders.NewOrder')"></span>
        </PButton>

      </div>
    </portal>

    <OrderDialog
      v-if="createDialogVisible" 
      transactionType="TO"
      :orderId="orderId"
      @close="onCreateClose" 
      @support="onCreateSupport"
    />

    <EditDialog 
      v-if="editDialogVisible"
      :showSorts="false"
      :orderId="orderId"
      @close="onEditClose" 
      view="orders"
    />

  </div>
</template>

<script>
import http from "@/http"
import { mapGetters } from "vuex"
import { format, add, sub } from "date-fns"
import OrderDialog from "../dialogs/OrderDialog"
import EditDialog from "../../reception/dialogs/EditDialog"
import { getConfig } from "@/config"

const config = getConfig()

export default {
  
  components: {
    OrderDialog,
    EditDialog,
  },

  props: {
    id: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      cols:
      
        config.theme === 'swa' ?
      
          [
            { text: this.$tk("Common.Order.OrderNo"), value: "id", sortable: true, component: "p-display-text", width: 110, align: "center", classes: "font-medium" },
            { text: this.$tk("Common.Order.ReceivalDate"), value: "dateArrived", sortable: true, component: "p-display-date", width: 110, align: "center" },
            { 
              nested: [            
                { text: this.$tk("Common.Order.RTI"), component: "p-display-product", componentConfig: { showTreatment: true }, aggregate: "p-display-product-images", align: "left", width: 240 },
                { text: this.$tk("Common.Order.Quantity"), value: "quantity", component: "p-display-number", aggregate: "p-display-sum", width: 80, align: "right" }, 
              ] 
            },
            { text: this.$tk("Common.Order.Freight"), value: "feeTransport", sortable: true, component: "p-display-price", align: "right", width: 80 },
            { text: this.$tk("Common.Order.Currency"), value: "currencyIdTo", sortable: true, component: "p-display-text", width: 90, align: "center" },
            { text: this.$tk("Common.Order.Address"), value: "addressTo", sortable: true, component: "p-display-address", config: { showPrimary: false } },
            { text: this.$tk("Common.Order.OrderedBy"), value: "createdBy", sortable: true, component: "p-display-user" },
            { text: this.$tk("Common.Order.Cartman"), value: "cartmanName", sortable: true, component: "p-display-text" },
            { text: this.$tk("Common.Order.TransportRef"), value: "shipmentRef", sortable: true, component: "p-display-text" },
            { text: this.$tk("Common.Order.MyReference"), value: "receiversRef", sortable: true, component: "p-display-text" },
            { text: this.$tk("Common.Order.CarNo"), value: "shipmentCarNumber", component: "p-display-text" },
            { text: this.$tk("Common.Order.CarNo2"), value: "shipmentCarNumber2", component: "p-display-text" },
            { text: 'Plomberingsnummer', value: "sealingId", component: "p-display-text" },
            { text: this.$tk("Common.Order.Status"), component: "p-display-status", align: "center" },
          ] 
          
        :
          
          [
            { text: this.$tk("Common.Order.OrderNo"), value: "id", sortable: true, component: "p-display-text", width: 110, align: "center", classes: "font-medium" },
            { text: this.$tk("Common.Order.ReceivalDate"), value: "dateArrived", sortable: true, component: "p-display-date", width: 110, align: "center" },
            { 
              nested: [            
                { text: this.$tk("Common.Order.RTI"), component: "p-display-product", componentConfig: { showTreatment: true }, aggregate: "p-display-product-images", align: "left", width: 240 },
                { text: this.$tk("Common.Order.Price"), value: "price", component: "p-display-price", width: 80, align: "right" },
                { text: this.$tk("Common.Order.Quantity"), value: "quantity", component: "p-display-number", aggregate: "p-display-sum", width: 80, align: "right" }, 
                { text: this.$tk("Common.Order.Amount"), value: "feePrice", component: "p-display-price", width: 80, align: "right" },
              ] 
            },
            { text: this.$tk("Common.Order.Freight"), value: "feeTransport", sortable: true, component: "p-display-price", align: "right", width: 80 },
            { text: this.$tk("Common.Order.Total"), value: "amountTo", sortable: true, component: "p-display-price", width: 110, align: "right" },
            { text: this.$tk("Common.Order.Currency"), value: "currencyIdTo", sortable: true, component: "p-display-text", width: 90, align: "center" },
            { text: this.$tk("Common.Order.Address"), value: "addressTo", sortable: true, component: "p-display-address", config: { showPrimary: false } },
            { text: this.$tk("Common.Order.OrderedBy"), value: "createdBy", sortable: true, component: "p-display-user" },
            { text: this.$tk("Common.Order.Transporter"), value: "transporterName", sortable: true, component: "p-display-text" },
            { text: this.$tk("Common.Order.TransportRef"), value: "shipmentRef", sortable: true, component: "p-display-text" },
            { text: this.$tk("Common.Order.MyReference"), value: "receiversRef", sortable: true, component: "p-display-text" },
            { text: this.$tk("Common.Order.DeliveryFrom"), sortable: true, component: "p-display-counterpart" },
            { text: this.$tk("Common.Order.Status"), component: "p-display-status", align: "center" },
          ]
          
      ,      
      orderId: "",      
      expandAll: true,
      filters: {
        term:             { value: "" },
        sendLower:        { value: format(sub(new Date(), { days: 21 }), "yyyy-MM-dd") },
        sendUpper:        { value: format(add(new Date(), { months: 1 }), "yyyy-MM-dd") },
        statuses: [
          { label: this.$tk("Common.OrderStatus.Open"), ids: [1, 2, 3, 10], value: true },
          { id: 4, value: true },
          { id: 6, value: false },
          { id: 9, value: true, visible: false },
          { id: 5, value: false },
        ]        
      },
      orderBy: {
        field: "dateArrived",
        descending: true
      },
      createDialogVisible: false,
      editDialogVisible: false
    }
  },  

  computed: {
    ...mapGetters([
      "customer",
      "location",
      "hintsVisible"
    ])
  },

  methods: {

    getOrders () {
      this.$refs.ordergrid.getOrders()     
    },

    onClick ({ order }) {
      
      if (order.interpoolingCompany) return
      
      // this.$router.replace({ name: "ordersMain", params: { id: order.id }})

      this.orderId = order.id
      if (order.transactionStatus === "Web" ||
          order.transactionStatus === "Ordered") {
        this.createDialogVisible = true
      } else {
        this.editDialogVisible = true
      }
    },

    onCreateSupport (orderId) {
      this.createDialogVisible = false
      this.$router.push({ name: "support.Tickets", query: { i: orderId }})
    },

    onCreateShow () {
      this.createDialogVisible = true
    },

    onCreateClose () {
      // this.$router.replace({ name: "ordersMain" })
      this.createDialogVisible = false
      this.orderId = ""
      this.getOrders()
    },

    onEditClose () {
      // this.$router.replace({ name: "ordersMain" })
      this.editDialogVisible = false
      this.orderId = ""      
      this.getOrders()
    },
    
  },

  watch: {
    id: {
      handler: async function (val) {
        
        if (val) {

          this.filters.sendLower.value = ""
          this.filters.sendUpper.value = ""
          this.filters.term.value = val

          try {
            
            const order = await http.get("Order", { 
              params: { 
                id: val,
                include: "id,transactionStatus"
              }
            })
            
            this.orderId = val
            
            if (order.transactionStatus === "Web" ||
              order.transactionStatus === "Ordered") {
              this.createDialogVisible = true
            } else {
              this.editDialogVisible = true
            }

          } catch (error) {
            this.$notifyError(error.reason)            
          }

        } else {

          this.orderId = ""
          this.createDialogVisible = false
          this.editDialogVisible = false

        }

      }, immediate: true

    }
  }

}
</script>
